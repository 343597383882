import { COURSE_TABS } from "./App";

export const CourseMobile = () => (
  <div className="sm:hidden bg-blue-700 py-8 my-8" id="course">
    <div className="container flex flex-col mx-auto px-4 duration-500">
      <h2 className="text-3xl font-medium text-white pb-2 opacity-100">
        Course
      </h2>
      <div className="bg-blue-500 my-4 rounded-md p-4 text-white text-sm text-justify leading bg-opacity-80">
        <h3 className="font-medium opacity-100 pb-1">Categories</h3>
        <p className="pb-2">
          The participants will complete standard sprint triathlon distances.
          Optionally, they may alter the course by enrolling in special
          categories. The names are completely random.
        </p>
        <p className="pb-2">
          <span className="font-medium">Category A la Carte</span>: do whichever
          leg as many times as you want.
        </p>
      </div>
      {["Swim", "Bike", "Run", "Drink"].map((headline, idx) => (
        <div className="mb-12">
          <h2 className="text-3xl font-medium text-white pb-2 opacity-75">
            {headline}
          </h2>
          <div className="text-white text-sm text-justify leading-normal opacity-80 flex flex-col">
            {COURSE_TABS[idx]}
          </div>
        </div>
      ))}
    </div>
  </div>
);
