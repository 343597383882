const MenuItem = ({ item }: any) => (
  <li>
    <a
      href={item.to}
      className="hover:text-blue-500  hover:border-blue-500 hover:border-b-2 duration-300  border-white pb-3 border-b-2 px-2"
    >
      {item.label}
    </a>
  </li>
);

const menuItems = [
  { label: "Course", to: "#course" },
  { label: "Details", to: "#details" },
  { label: "Checklist", to: "#checklist" },
];

export const Navbar = () => (
  <div className="py-4 w-full flex justify-between items-center">
    <div className="flex space-x-8">
      <h1 className="sm:px-2 text-4xl" data-testid="logo">
        <div className="text-gray-800 ">Tiburon</div>{" "}
        <div className="-mt-1 text-blue-500">Triathlon</div>
      </h1>
    </div>
    <ul className="sm:flex px-2 space-x-10 text-base tracking-wide items-center text-gray-600 hidden">
      {menuItems.map((item) => (
        <MenuItem item={item} />
      ))}
    </ul>
  </div>
);
