import { useEffect, useState } from "react";
import { CardMobile, Image } from "./CardMobile";
import { CardProps, CardType } from "./types";

export const date = "August 27, 2023";

export const Cards = () => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <div
        className={`hidden sm:flex justify-center items-center w-full sm:h-auto ${
          loaded ? "scale-100" : "scale-0"
        } duration-1000 transform delay-200`}
      >
        <div className="sm:w-3/4 sm:mt-60">
          <div className="hidden sm:flex">
            {cards.map((card, idx) => (
              <Card item={card} key={idx} />
            ))}
          </div>
        </div>
      </div>
      <div className="sm:hidden flex overflow-x-auto no-scrollbar snap snap-x snap-mandatory py-10">
        {cards.map((card, idx) => (
          <CardMobile
            card={card}
            key={idx}
            loaded={loaded}
            setLoaded={setLoaded}
          />
        ))}
      </div>
    </>
  );
};

const Card: React.FC<CardProps> = ({ item }) => {
  const { offset, bg, text, font, distance } = item;
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <div
      className={`snap-center group w-screen sm:w-full h-auto sm:h-3/4 mt-10 ${offset} hover:-translate-y-5 transform duration-500 shadow-sm`}
    >
      <div
        className={`${bg} h-96 hover:rounded-t-sm duration-500 flex items-center justify-center`}
      >
        <Image name={text} loaded={loaded} setLoaded={setLoaded} />
      </div>
      <div className="h-1/4 text-center px-4">
        <div className="h-1/5 text-gray-400 font-light border-b border-gray-100 text-xs py-4">
          {date}
        </div>
        <div className="h-3/5 border-b border-gray-100 py-6 group-hover:opacity-100 opacity-50 duration-500">
          <p
            className={`uppercase font-extralight text-4xl ${font} tracking-widest`}
          >
            {text}
          </p>
        </div>
        <div className="h-1/5 py-4 text-xs text-gray-400 group-hover:text-gray-600 duration-300 font-light">
          {distance}
        </div>
      </div>
    </div>
  );
};

const cards: CardType[] = [
  {
    bg: "bg-[#0284C7]",
    font: "text-[#0284C7]",
    text: "Swim",
    distance: "800 yards",
  },
  {
    bg: "bg-[#16A34A]",
    font: "text-[#16A34A]",
    text: "Bike",
    offset: "sm:-mt-12",
    distance: "12 miles",
  },
  {
    bg: "bg-[#DB2777]",
    font: "text-[#DB2777]",
    text: "Run",
    offset: "sm:-mt-24",
    distance: "3.1 miles",
  },
  {
    bg: "bg-[#6D28D9]",
    font: "text-[#6D28D9]",
    text: "Picnic",
    offset: "sm:-mt-12",
    distance: "5 White Claws",
  },
];
