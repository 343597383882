import { date } from "./Card";
import { bikeIllustration, swimIllustration, runIllustration } from "./images";
import { CardMobileProps, ImageProps } from "./types";

export const CardMobile: React.FC<CardMobileProps> = ({
  card,
  loaded,
  setLoaded,
}) => {
  return (
    <div className="snap-center min-w-full text-center flex items-center -mr-12 flex-col h-full duration-300">
      <div className="shadow w-4/5 flex items-center justify-center  flex-col">
        <div
          className={`${card.bg} w-full flex items-center justify-center h-80 rounded-t-md`}
        >
          <Image name={card.text} loaded={loaded} setLoaded={setLoaded} />
        </div>
        <div className="h-1/5 text-center px-4 w-full">
          <div className="h-1/5 text-gray-400 font-light border-b border-gray-100 text-xs py-4">
            {date}
          </div>
          <div className="h-3/5 border-b border-gray-100 py-6 group-hover:opacity-100 opacity-50 duration-500">
            <p
              className={`uppercase font-extralight text-4xl ${card.font} tracking-widest`}
            >
              {card.text}
            </p>
          </div>
          <div className="h-1/5 py-4 text-xs text-gray-400 group-hover:text-gray-600 duration-300 font-light">
            {card.distance}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Image: React.FC<ImageProps> = ({ name, loaded, setLoaded }) => {
  let src;

  switch (name) {
    case "Swim":
      src = swimIllustration;
      break;
    case "Bike":
      src = bikeIllustration;
      break;
    case "Run":
      src = runIllustration;
      break;
    default:
  }

  if (src) {
    return (
      <img
        className={loaded ? "visible" : "hidden"}
        src={src}
        alt={name}
        onLoad={() => setLoaded(true)}
      />
    );
  } else {
    return <span className="text-8xl">🍾</span>;
  }
};
