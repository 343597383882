import React from "react";

interface SectionTypes {
  id: string;
  children: React.ReactNode;
  title: string;
}

export const Section: React.FC<SectionTypes> = ({ title, id, children }) => {
  return (
    <div className="py-8 pb-20 text-justify sm:w-3/4 sm:mx-auto" id={id}>
      <div className="container flex flex-col mx-auto px-4 duration-500">
        <h2 className="text-3xl sm:text-4xl font-medium text-black pb-2 opacity-100">
          {title}
        </h2>
        {children}
      </div>
    </div>
  );
};
