export const allItems = [
  {
    category: "Swim",
    items: [
      {
        label: "Wetsuit",
        buy: {
          link: "https://shop.sportsbasement.com/collections/triathlon?refinementList%5Bvendor%5D%5B0%5D=Blue%20Seventy",
          price: 349,
        },
        rent: {
          link: "https://shop.sportsbasement.com/collections/basic-wetsuit-rentals",
          price: 35,
        },
      },
      {
        category: "swim",
        label: "Neoprene Cap",
        buy: {
          link: "https://shop.sportsbasement.com/products/adjustable-thermal-skull-cap",
          price: 45,
        },
        rent: {
          link: "https://rentals.sportsbasement.com/product/123?ship",
          price: 5,
        },
        isOptional: true,
      },
      {
        category: "swim",
        label: "Goggles",
        buy: {
          link: "https://shop.sportsbasement.com/collections/swim-goggles",
          price: 19,
        },
      },
      {
        category: "swim",
        label: "Swim Cap",
        buy: {
          link: "https://www.amazon.com/TYR-Wrinkle-Free-Silicone-Black/dp/B0025UIF46/ref=sr_1_2?dchild=1&keywords=swim+cap+tyr&qid=1627172691&sr=8-2",
          price: 10,
        },
      },
      {
        category: "swim",
        label: "Body Glide",
        buy: {
          link: "https://www.amazon.com/Glide-Original-Anti-Chafe-1-5oz-2-pack/dp/B0779KPX4D/ref=sr_1_3?dchild=1&keywords=body+glide&qid=1627172728&sr=8-3",
          price: 20,
        },
      },
      {
        category: "swim",
        label: "Flip Flops",
        buy: {
          link: "https://www.amazon.com/adidas-Adilette-Shower-Slide-Sandal/dp/B0716XDK7N/ref=sr_1_6?dchild=1&keywords=pool+slides+adidas&qid=1627172784&sr=8-6",
          price: 22,
        },
        isOptional: true,
      },
      {
        category: "swim",
        label: "Towel",
        buy: {
          link: "https://www.amazon.com/Microfiber-Travel-Workout-Camping-Backpacking/dp/B08532C4HT",
          price: 13,
        },
        isOptional: true,
      },
      {
        label: "Swimsuit",
        buy: {
          link: "https://shop.sportsbasement.com/search?q=swim%20suit",
          price: 30,
        },
        isOptional: true,
      },
      {
        label: "Trisuit",
        buy: {
          link: "https://shop.sportsbasement.com/search?q=tri%20suit",
          price: 160,
        },
        isOptional: true,
      },
      {
        label: "Ear Plugs",
        buy: {
          link: "https://www.amazon.com/Speedo-Ergo-Plugs-Silver-Size/dp/B003VRWZ3Y",
          price: 5,
        },
        isOptional: true,
      },
    ],
  },
  {
    category: "Bike",
    items: [
      {
        label: "Bike",
        rent: {
          link: "https://rentals.sportsbasement.com/rent/road-bikes/road-carbon",
          price: 80,
        },
        buy: {
          link: "https://shop.sportsbasement.com/products/synapse-105?variant=36503646994504",
          price: 1900,
        },
      },
      {
        label: "Helmet",
        buy: {
          link: "https://shop.sportsbasement.com/products/agilis-mips",
          price: 90,
        },
        rent: {
          link: "https://rentals.sportsbasement.com/rent/road-bikes/road-carbon",
          price: 0,
        },
      },
      {
        label: "Sunglasses",
        buy: {
          link: "https://www.amazon.com/Tifosi-Matte-Black-Frame-Bridge/dp/B07PDN6Z76",
          price: 49,
        },
      },
      {
        label: "Bike Shoes",
        buy: {
          link: "https://shop.sportsbasement.com/collections/bike-shoes",
          price: 100,
        },
        isOptional: true,
      },
      {
        label: "Socks",
        buy: {
          link: "https://www.specialized.com/us/en/shop/sale/specialized-mens/mens-clothing--shoes/mens-cycling-socks/c/socksmen",
          price: 18,
        },
        isOptional: true,
      },
      {
        label: "Bike Clothes",
        buy: {
          link: "https://quirkyjerseys.com/collections/new-arrivals/products/dog-print-cycling-jersey-for-women",
          price: 49,
        },
        isOptional: true,
      },
      {
        label: "Water Bottle",
        buy: {
          link: "https://www.amazon.com/CamelBak-Podium-Chill-21OZ-Black/dp/B07HGR3YZ7",
          price: 14,
        },
        isOptional: true,
      },
    ],
  },
  {
    category: "Run",
    items: [
      {
        label: "Running Shoes",
        buy: {
          link: "https://shop.sportsbasement.com/collections/running",
          price: 100,
        },
      },
      {
        label: "Run Clothes",
        buy: {
          link: "https://www.etsy.com/listing/937864772/plan-for-today-shirt-funny-running?",
          price: 7,
        },
        isOptional: true,
      },
      {
        label: "Hat",
        buy: {
          link: "https://www.amazon.com/Nike-Golf-Dri-FIT-Swoosh-Perforated/dp/B005HJO52I",
          price: 34,
        },
        isOptional: true,
      },
    ],
  },
  {
    category: "Other",
    items: [
      {
        label: "Sunscreen",
        buy: {
          link: "https://www.amazon.com/Coppertone-Continuous-Sunscreen-Spectrum-5-5-Ounce/dp/B074WBHHYY",
          price: 13,
        },
      },
      {
        label: "Watch",
        buy: {
          link: "https://www.amazon.com/New-Apple-Watch-GPS-40mm-Aluminum/dp/B08J5XF5SR",
          price: 329,
        },
        isOptional: true,
      },
      {
        label: "Sports Beans or other food",
        buy: {
          link: "https://www.amazon.com/Jelly-Belly-Sport-Beans-Beans-Click/dp/B0787F8M6M",
          price: 19,
        },
        isOptional: true,
      },
      {
        label: "Thirst for Victory at All Costs",
        buy: {
          link: "https://www.youtube.com/watch?v=_XUu3_pLPUE",
        },
      },
    ],
  },
];
