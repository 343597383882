import { useState } from "react";
import swim from "./assets/swim.png";
import bike from "./assets/bike.png";
import run from "./assets/run.png";
import { Cards, date } from "./Card";
import { Tab, TabList, TabPanel, Tabs } from "./Tabs";
import { Navbar } from "./Navbar";
import { RaceDayChecklist } from "./RaceDayChecklist";
import { Section } from "./Section";
import { CourseMobile } from "./CourseMobile";
import ReactGA from "react-ga";

function App() {
  const [active, setActive] = useState<number>(0);

  const handleTab = (idx:number) => {
    setActive(idx);
    ReactGA.event({
      category: "Tab",
      action: categories[idx],
    });
  };

  return (
    <>
      <div className="sm:container flex flex-col mx-auto sm:h-full sm:px-10 px-4 py-4 duration-500">
        <Navbar />
        <Cards />
      </div>

      <div
        className="hidden sm:block w-full overflow-hidden mt-20 sm:py-40"
        id="course"
      >
        <div className="h-auto w-full min-w-full scale-100 bg-gradient-to-r from-gray-50 to-gray-100 -rotate-3 relative flex justify-center">
          <div className="mx-8 sm:w-3/4 bg-gradient-to-l from-blue-800 to-blue-900 border-blue-900 rounded-md rotate-3 shadow-xl p-5 sm:p-10">
            <h2 className="text-blue-100 text-4xl">Course</h2>
            <Tabs>
              <TabList>
                {categories.map((item, idx) => (
                  <Tab
                    title={item}
                    key={idx}
                    isActive={idx === active}
                    onClick={() => handleTab(idx)}
                  />
                ))}
              </TabList>
              {COURSE_TABS.map((panel, idx) => (
                <TabPanel isActive={idx === active}>{panel}</TabPanel>
              ))}
              {[swim, bike, run].map((item, idx) => (
                <a
                  key={idx}
                  href={item}
                  target="_blank"
                  rel="noreferrer"
                  className={`sm:w-1/3 sm:h-1/3 w-full h-full rounded-md sm:rounded-2xl ${
                    idx === active ? "" : "hidden"
                  }`}
                >
                  <img src={item} alt={item.toString()} />
                </a>
              ))}
            </Tabs>
          </div>
        </div>
      </div>

      <CourseMobile />

      <Section title="Race Day Details" id="details">
        <p>
          {date}: race begins at 8.30am to ensure we all get a parking spot at{" "}
          <a
            href="https://www.marincountyparks.org/parkspreserves/parks/paradise-beach"
            className="text-blue-500 border-blue-500 border-b-2 duration-300  hover:border-white pb-0.5 hover:border-b-2"
          >
            Paradise Beach Park
          </a>
          . As of this year, parking is now free. Please note that the park still has a <b>strict no-pets policy</b> and the park rangers will make you turn around if you try to bring Fido or Bella with you.
        </p>
        <p>This is a closed event for family and friends.</p>
        <p>
          You are responsible for your own timekeeping. There will be no awards
          or ranking. This way we'll all be winners. However, no participation
          awards will be given either.
        </p>
        <p>
          There are no rules except for <b>No Injuries</b> and <b>No Drowning</b>. If you get
          injured or if you drown, you'll be disqualified.
        </p>
        <p>
          Food &amp; drink: please bring your own fruit / pastries / water /
          Gatorade / White Claws and others for a picnick after the race.
        </p>
      </Section>

      <Section title="Race Day Checklist" id="checklist">
        <RaceDayChecklist />
      </Section>
    </>
  );
}

export default App;

export const COURSE_TABS = [
  <p>
    We'll begin on a sandy beach and swim clockwise 0.45mi (800 yards) in
    Paradise Cove. This section of the bay is generally calm and warm, but I'd
    still suggest that you wear a wetsuit. As you sight, you'll get to see
    various Tiburon villas on the left, including this tiny Mediterranean
    cottage that belongs to a former CEO of Autodesk, and Richmond Bridge on the
    right.
  </p>,

  <p>
    After you get out of your wetsuits in the parking lot, you'll ride clockwise
    on Paradise Drive to Tiburon and then continue on a bike path to Trestle
    Glen Boulevard that will close the loop. You'll have to ride about 2 miles
    towards Corte Madera before making a u-turn and returning to Paradise Park.
    The route is fairly flat and has limited traffic.
  </p>,
  <p>
    The final leg of the race is an out-and-back run. It starts with 60ft of
    climbing on the first half a mile but it also finishes with 60 ft of
    descending at the end. Amazing how that works!
  </p>,
  <p>
    After finishing we'll meet for a potluck picnic at the nearby tables. Feel
    free to bring food and drink to share with others.
  </p>,
];

const categories = ["🏊 Swim", "🚴 Bike", "🏃 Run", "🎉 Picnic"];
