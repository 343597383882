// @ts-nocheck

const Tabs = ({ children }: any) => {
  return (
    <div className="flex mt-8 text-white sm:space-x-16 sm:flex-row flex-col gap-y-4">
      {children}
    </div>
  );
};

const Tab = ({ title, onClick }: any) => (
  <li
    className="bg-blue-800 cursor-pointer hover:text-white hover:bg-blue-900 duration-300 px-4 py-1 rounded"
    onClick={onClick}
  >
    {title}
  </li>
);

const TabList = ({ children }: any) => (
  <ul className="flex flex-row sm:flex-col space-x-2 sm:space-x-0 sm:space-y-4 text-blue-50 text-sm w-full sm:w-1/6">
    {children}
  </ul>
);

const TabPanel = ({ children, isActive }: any) => (
  <div
    className={`w-full sm:w-1/3  text-sm leading-relaxed transform duration-300 ${
      isActive ? "" : "hidden"
    }`}
  >
    {children}
  </div>
);

export { Tab, TabList, TabPanel, Tabs };
