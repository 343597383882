import { useState } from "react";
import { allItems } from "./checklistData";
import ReactGA from "react-ga";
import { CheckListItemProps, ILink } from "./types";

const Link: React.FC<ILink> = ({ content, label }) => {
  const { link, price } = content;
  const color = label === "Buy" ? "bg-green-500" : "bg-yellow-500";

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={`px-4 py-1 rounded upper text-xs bg-opacity-20  ${color}`}
    >
      {label} {price && <>${price}</>}
    </a>
  );
};

const CheckListItem: React.FC<CheckListItemProps> = ({ item }) => {
  const { label, buy, rent, isOptional } = item;
  const [checked, setChecked] = useState<boolean>(false);

  const handleCheck = () => {
    setChecked((prevState) => !prevState);
    ReactGA.event({
      category: "Checklist",
      action: label,
      value: checked ? 1 : 0,
    });
  };

  return (
    <div
      className={`relative flex items-start py-4 duration-300 ${
        checked ? "opacity-20" : ""
      }`}
    >
      <div className="min-w-0 flex-1 text-sm">
        <label htmlFor={`id${label}`} className="font-medium text-gray-700">
          {label}
        </label>
        <div
          id="comments-description"
          className={`text-gray-500 mt-2 flex space-x-2 duration-300 ${
            checked ? "hidden" : ""
          }`}
        >
          {rent && <Link label="Rent" content={rent} />}
          {buy && <Link label="Buy" content={buy} />}
        </div>
      </div>
      <div className="ml-3 flex items-center h-5">
        {isOptional && (
          <span className="text-xs bg-gray-100 px-2 py-1 rounded text-gray-400 mr-2 opacity-70">
            Optional
          </span>
        )}
        <input
          id={`id${label}`}
          aria-describedby="comments-description"
          name="comments"
          type="checkbox"
          onChange={handleCheck}
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
      </div>
    </div>
  );
};

export const RaceDayChecklist = () => {
  return (
    <div className=" sm:flex sm:space-x-12 sm:mt-4 duration-300">
      {allItems.map(({ category, items }, idx) => (
        <div className="mb-8 sm:w-1/4" key={idx}>
          <h2 className="text-gray-600 text-2xl pb-2 pt-4 font-medium">
            {category}
          </h2>
          <fieldset className="border-t border-b border-gray-200">
            <legend className="sr-only">Swim</legend>
            <div className="divide-y divide-gray-200">
              {items.map((item: any, internal: number) => (
                <CheckListItem item={item} key={internal} />
              ))}
            </div>
          </fieldset>
        </div>
      ))}
    </div>
  );
};
